var Prodo_Site = {
	SiteWrapper: $("html"),
	// event specific properties
	RegisteredEvents: [],
	RegisterEvent: function(eventName, func) {
		this.RegisteredEvents.push({
			eventName: eventName,
			func: func
		});
	},
	TriggerEvent: function(eventName, parameters) {
		var matchingRegisteredEvents = $.grep(this.RegisteredEvents, function(registeredEvent, index) {
			return registeredEvent.eventName == eventName;
		});

		if (matchingRegisteredEvents.length) {
			matchingRegisteredEvents[0].func(parameters);
		} else {
			this.SiteWrapper.trigger(eventName);
		}
	},
	BindEvent: function(eventName, func) {
		this.SiteWrapper.on(eventName, func);
	},
	// module specific properties
	AllModules: [],
	RegisterModule: function(customModule) {
		this.AllModules.push(customModule);
	},
	InitializeModules: function() {
		$.each(Prodo_Site.AllModules, function(index, customModule) {
			if (typeof customModule === 'function') {
				customModule();
			}
		});
	}
}

$(document).ready(function() {
	
	$(document).foundation();
	Prodo_Site.InitializeModules();
	
	setTimeout(function(){
		$('.pagination').removeAttr('aria-label').removeAttr('role').wrap('<nav aria-label="Pagination"></nav>');
	}, 100);
	
});

var windowResizeTimer;
window.onresize = function() {
	clearTimeout(windowResizeTimer);
	windowResizeTimer = setTimeout(function() {
		Prodo_Site.TriggerEvent('resizeEnd');
	}, 100);
};
